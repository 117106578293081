// list of factors
enum Factors {
  LAND_SIZES = 1,
  STREET = 2,
  LOCATION = 3,
  LOCATION_SPECS = 4,
  FACING = 5,
  CURB = 6,
  PLOT_SPECS = 7,
  DIRECTION = 8,
  SABAH_SEA_SPECS = 9,
}

export default Factors;
